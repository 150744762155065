import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import './index.css'
import { FaGithubSquare, FaLinkedin } from 'react-icons/fa'

import HeadPic from '../images/head.jpg'

const IndexPage = ({data}) => (
  <Layout>
    <BackgroundImage
      style={{
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        height: 500,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
      fluid={data.file.childImageSharp.fluid} 
    >
      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `flex-end`,
        }}>
        <h1 style={{color: `#c5e4ff`, textShadow: `1px 1px 2px #2d61a3`}}>
          Blue Ridge Mountains
        </h1>
        <small style={{color: `#c5e4ff`}}>November 10, 2019</small>
        <small style={{color: `#81bdf2`}}>Asheville, NC</small>
      </div>
    </BackgroundImage>
    <div className="aboutme index container">
      <div className="head aboutme_head">
        <h1>About Me</h1>
      </div>
      <div className="aboutme_body">
        <Img style={{
          flex: `0 0 300px`,
          borderRadius: `10px`, 
          boxShadow: `2px 2px 15px #bfc1cc`
          }} fluid={data.avatarImg.childImageSharp.fluid} />
        <div className="aboutme_body_text">
          <div className="text">
            <p>"In the northern ocean there is a fish <span role="img" aria-label="fish">🐟</span>, called the k’un, I do not know how many thousand li in size. This k’un changes into a bird <span role="img" aria-label="eagle">🦅</span>, called the p’eng."</p>
            <p style={{
              fontSize: `0.8em`
            }}>From <a href="http://www.dankalia.com/literature/chuantzu/chn025.htm" target="_blank" rel="noopener noreferrer"><i>A Happy Excursion</i> (逍遥游)</a>, By <a href="https://en.wikipedia.org/wiki/Zhuang_Zhou" target="_blank" rel="noopener noreferrer">Chuang Tzu(庄子)</a>. </p>
          </div>
          <div className="contacts">
            <a style={{marginRight: `15px`}} className="contacts--icon" target="_blank" rel="noopener noreferrer" href="https://github.com/hmqk1995/"><FaGithubSquare /></a>
            <a className="contacts--icon" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/kun-qian-8314b2117/"><FaLinkedin /></a>
          </div>
        </div>
      </div>
    </div>
    <div className="friends_link index container">
      <div className="head friends_link_head">
        <h1>Friends</h1>
      </div>
      <div className="friends_link_body">
        <a target="_blank" rel="noopener noreferrer" href="http://huangxuan.me/">Hux Blog</a>
      </div>
    </div>
    <SEO title="Home" />
  </Layout>
)

export const query = graphql`
query {
  file(base: {eq: "head.jpg"}) {
    id
    base
    childImageSharp {
      id
      fluid(quality: 100, maxWidth: 2000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  avatarImg: file(relativePath: { eq: "avatar.jpg" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`
export default IndexPage
